import UserIcon from "@material-ui/icons/Group";
import React from "react";
import { Admin, fetchUtils, Resource } from "react-admin";
import { getUserTokenFromStorage } from "shared/src/utils/storage-variables";
import authProvider from "../providers/AuthProvider";
import { addUploadFeature } from "../providers/DataProviderwithUpload";
import { Permissions } from "../providers/Interfaces";
import { oneFidDataProvider } from "../providers/OneFidDataProvidor";
import { DeviceShow } from "./Devices/DeviceShow";
import { DevicesList } from "./Devices/DevicesList";
import { OrderEdit } from "./Orders/OrderEdit";
import { OrdersList } from "./Orders/OrdersList";
import { ProductShow } from "./Products/ProductShow";
import { ProductsList } from "./Products/ProductsList";
import { CreateUser } from "./Users/UserCreate";
import { UserEdit } from "./Users/UserEdit";
import { UserShow } from "./Users/UserShow";
import { UsersList } from "./Users/UsersList";
import { MobileMeasurementsList } from "./MobileMeasurements/MobileMeasurementsList";
import { MobileMeasurementsShow } from "./MobileMeasurements/MobileMeasurementsShow";
import { PartnerList } from "./PartnerManager/PartnerList";
import { PartnerCreate } from "./PartnerManager/PartnerCreate";
import { PartnerEdit } from "./PartnerManager/PartnerEdit";
import { LoginPage } from "./Login/Login";

export const httpClient = (url: string, options: { headers: Headers }) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: "application/json" });
  }

  if (getUserTokenFromStorage()) {
    options.headers.set("Authorization", `Token ${getUserTokenFromStorage()}`);
  }

  return fetchUtils.fetchJson(url, options);
};

export const AdminView = (): JSX.Element => {
  const baseUrl =
    (window.env && window.env.ONEFID_CORE_API) ||
    "https://core.onefid.com/api/v2";
  const dataProvider = oneFidDataProvider(baseUrl, httpClient);
  const uploadCapableDataProvider = addUploadFeature(dataProvider);

  return (
    <Admin
      dataProvider={uploadCapableDataProvider}
      authProvider={authProvider}
      locale="en"
      loginPage={LoginPage}
    >
      {(permissions: Permissions) => [
        permissions.includes("partner_can_use_commerce_plugin") && (
          <Resource
            create={PartnerCreate}
            edit={PartnerEdit}
            list={PartnerList}
            name="partners/domains"
            options={{ label: "Plugin Manager" }}
          />
        ),
        permissions.includes("partner_can_manage_users") && (
          <Resource
            show={!permissions.includes("partner_has_safety_shoes") && UserShow}
            edit={permissions.includes("partner_has_safety_shoes") && UserEdit}
            icon={UserIcon}
            list={UsersList}
            create={
              permissions.includes("partner_has_safety_shoes") && CreateUser
            }
            name="users"
            options={{ label: "User Manager" }}
          />
        ),
        permissions.includes("partner_can_manage_orders") && (
          <Resource
            edit={OrderEdit}
            list={OrdersList}
            name="orders"
            options={{ label: "Order Manager" }}
          />
        ),
        permissions.includes("partner_can_manage_products") && (
          <Resource
            show={ProductShow}
            list={ProductsList}
            name="my-products"
            options={{ label: "Product Manager" }}
          />
        ),
        permissions.includes("partner_can_manage_devices") && (
          <Resource
            show={DeviceShow}
            list={DevicesList}
            name="scan-devices"
            options={{ label: "Device Manager" }}
          />
        ),
        permissions.includes("partner_can_read_mobilemeasurements") && (
          <Resource
            show={MobileMeasurementsShow}
            list={MobileMeasurementsList}
            name="mobile-scans-statistics"
            options={{ label: "Mobile Measurements" }}
          />
        ),
      ]}
    </Admin>
  );
};

import * as XLSX from "xlsx";
import { OrderApi, UserApi } from "../providers/Interfaces";

export const displayUserIdentifier = (record: UserApi | OrderApi): string => {
  const { employee_number: employeeNumber, email, uuid } = record;
  if (employeeNumber) {
    return employeeNumber;
  }
  if (email) {
    return email;
  }
  if (uuid) {
    return uuid;
  }

  return "";
};

export const convertLastWidthCategoryToSteitzWidth = (
  lwc: number
): string | undefined => {
  const lastWidthCategorySteitzMap: Map<number, "S" | "NB" | "XB" | "XXB"> =
    new Map([
      [1, "S"],
      [2, "S"],
      [3, "NB"],
      [4, "XB"],
      [5, "XXB"],
    ]);

  return lastWidthCategorySteitzMap.get(lwc);
};

export type Dictionary<T> = {
  [key: string]: T;
};

export const nestedDictHasValueForKey = (
  nestedDict: Dictionary<Dictionary<number>>,
  key: string
): boolean => Object.values(nestedDict).some((value) => value[key]);

// split text at - then capitalize string
export const splitAndCapitalize = (str: string): string => {
  return str
    .split("-")
    .map(function capitalize(part) {
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(" ");
};

export const isValidHttpUrl =
  (message: string | undefined = "Must be a URL") =>
  (str: string): string | false => {
    if (!str) return false;

    let url;
    try {
      url = new URL(str);
    } catch {
      return message;
    }

    if (url.protocol === "http:" || url.protocol === "https:") {
      return false;
    }

    return message;
  };

export const exportToExcel = (
  data: any,
  fields: string[],
  filename: string
) => {
  const filteredData = data.map((item: any) => {
    const filteredItem: { [key: string]: any } = {}; // Type assertion
    fields.forEach((field) => {
      filteredItem[field] = item[field];
    });

    return filteredItem;
  });

  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};
